/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
}


.glass_morphism {
    background: rgba(31, 31, 31, 0.60);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.orangeBG {
    @apply bg-gray-100

}

.touchScroll {
    -webkit-overflow-scrolling: touch;
}

.snapScrollbar {
    scroll-snap-type: x mandatory;
}

.scrollAlignCenter {
    scroll-snap-align: center;
}


.transitionfix {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0)
}

.overlay {
    z-index: 1;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    will-change: opacity;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 990px;
}


.fadeInAnimation {
    transition: all;
    animation: fadeIn 0.4s ease-in-out;
}


.fadeOut {
    transition: all;
    animation: fadeIn 0.4s ease-in-out;
}

@keyframes fadeOut {
    0% {
        transform: translate(0, 0px);
    }
    90% {
        transform: translate(0, 30rem);
    }
    100% {
        opacity: 0;
    }
}

.hiddenStoli {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, 10rem);
}

.visibleStoli {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.9s, opacity 0.2s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    0% {
        transform: translate(0, 30rem);
    }
    100% {
        transform: translate(0, 0px);
    }
}
